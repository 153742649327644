function PlayNever() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <g opacity="0.2">
      <path d="M18.6828 12.0697C18.8589 12.1813 19.0029 12.337 19.1006 12.5213C19.1982 12.7057 19.2461 12.9123 19.2396 13.1207C19.2331 13.3292 19.1723 13.5324 19.0633 13.7102C18.9543 13.8881 18.8008 14.0344 18.618 14.1349L8.97837 19.4245C8.7957 19.5247 8.59009 19.5756 8.38177 19.5722C8.17344 19.5689 7.96958 19.5113 7.79025 19.4052C7.61092 19.2992 7.4623 19.1482 7.35901 18.9673C7.25572 18.7863 7.20132 18.5816 7.20117 18.3733V6.98045C7.20114 6.76563 7.25878 6.55474 7.36806 6.36979C7.47735 6.18485 7.63428 6.03263 7.82248 5.92903C8.01067 5.82543 8.22322 5.77425 8.43794 5.78084C8.65266 5.78742 8.86168 5.85152 9.04317 5.96645L18.6828 12.0697Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6816 13.0093L9.60117 9.15965V16.3453L15.6816 13.0093ZM18.618 14.1349C18.8008 14.0344 18.9543 13.8881 19.0633 13.7102C19.1723 13.5324 19.2331 13.3292 19.2396 13.1207C19.2461 12.9123 19.1982 12.7057 19.1006 12.5213C19.0029 12.337 18.8589 12.1813 18.6828 12.0697L9.04317 5.96645C8.86168 5.85152 8.65266 5.78742 8.43794 5.78084C8.22322 5.77425 8.01067 5.82543 7.82248 5.92903C7.63428 6.03263 7.47735 6.18485 7.36806 6.36979C7.25878 6.55474 7.20114 6.76563 7.20117 6.98045V18.3733C7.20132 18.5816 7.25572 18.7863 7.35901 18.9673C7.4623 19.1482 7.61092 19.2992 7.79025 19.4052C7.96958 19.5113 8.17344 19.5689 8.38177 19.5722C8.59009 19.5756 8.7957 19.5247 8.97837 19.4245L18.618 14.1349Z" fill="white"/>
    </g>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00078 17.1851L16.8176 11.3255L6.00078 4.56475V17.1851ZM17.3888 12.3815C17.5735 12.2817 17.7288 12.1351 17.8391 11.9564C17.9495 11.7778 18.011 11.5733 18.0176 11.3634C18.0241 11.1535 17.9755 10.9456 17.8765 10.7603C17.7776 10.5751 17.6317 10.4191 17.4536 10.3079L6.63678 3.54715C6.45514 3.43362 6.24641 3.37078 6.03228 3.36516C5.81815 3.35954 5.60641 3.41133 5.41906 3.51518C5.23171 3.61902 5.07556 3.77111 4.96684 3.95568C4.85812 4.14024 4.80078 4.35054 4.80078 4.56475V17.1851C4.80067 17.3931 4.85457 17.5974 4.9572 17.7782C5.05984 17.959 5.20769 18.1101 5.38626 18.2165C5.56484 18.323 5.76801 18.3812 5.97587 18.3856C6.18373 18.3899 6.38914 18.3401 6.57198 18.2411L17.39 12.3815H17.3888Z" fill="white"/>
    <path d="M0.180504 1.0535C0.0648169 0.937658 -0.000112377 0.780599 1.46009e-07 0.61688C0.000112669 0.453161 0.0652578 0.296192 0.181104 0.180504C0.296951 0.0648169 0.454009 -0.000112375 0.617729 1.46002e-07C0.781448 0.000112667 0.938417 0.0652578 1.0541 0.181104L21.4193 20.5463C21.535 20.662 21.6 20.8189 21.6 20.9825C21.6 21.1461 21.535 21.303 21.4193 21.4187C21.3036 21.5344 21.1467 21.5994 20.9831 21.5994C20.8195 21.5994 20.6626 21.5344 20.5469 21.4187L0.181704 1.0535H0.180504Z" fill="white"/>
  </svg>
}
export default PlayNever